import EntryProxy from '@/app/proxies/EntryProxy';
import MailDomainProxy from '@/app/proxies/MailDomainProxy';
import IbanProxy from '@/app/proxies/IbanProxy';

export default {
    namespaced: true,
    state: {
        entry: {
            tickets: [ ( '0' + String( Math.round( Math.random() * 99 ) ) ).substr( -2 ) ],
            kit: true,
            av: false
        }
    },

    getters: {
        all: state => state.entry
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .create( context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        checkMailDomain: ( context, payload ) =>
        {
            // payload = { emailaddress: <emailaddress> }

            return new MailDomainProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return error;
                    // return Promise.reject( error );
                });
        },

        checkIban: ( context, payload ) =>
        {
            // payload = { iban: <iban> }

            return new IbanProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        }
    }
};
