import Vue from 'vue';
import Router from 'vue-router';
import store from '@/app/store';

import Landing from '@/app/pages/Landing';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: Landing,
            meta: {
                pageType: 'promotion-landing'
            }
        },

        {
            path: '/geluksgetal',
            name: 'Geluksgetal',
            component: () =>
            {
                return import( /* webpackChunkName: "number" */ '@/app/pages/Number' );
            },
            meta: {
                pageType: 'promotion-content'
            }
        },

        {
            path: '/score',
            name: 'Score',
            component: () =>
            {
                return import( /* webpackChunkName: "score" */ '@/app/pages/Score' );
            },
            meta: {
                pageType: ''
            }
        },

        {
            path: '/gegevens',
            name: 'Naw',
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            },

            children: [
                {
                    path: '0',
                    name: 'Naw0',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'naw' */ '@/app/pages/naw/Naw0' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 0
                    }
                },
                {
                    path: '1',
                    name: 'Naw1',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'naw' */ '@/app/pages/naw/Naw1' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 1
                    }
                },
                {
                    path: '2',
                    name: 'Naw2',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'naw' */ '@/app/pages/naw/Naw2' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 2
                    }
               },
                {
                    path: '3',
                    name: 'Naw3',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'naw' */ '@/app/pages/naw/Naw3' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 3
                    }
                },
                {
                    path: '4',
                    name: 'Naw4',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'naw' */ '@/app/pages/naw/Naw4' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 4
                    }
                }
            ]
        },

        {
            path: '/bedankt',
            name: 'Bedankt',
            component: () =>
            {
                return import( /* webpackChunkName: "bedankt" */ '@/app/pages/Bedankt' );
            },
            meta: {
                pageType: 'promotion-confirmation'
            }
        }
    ],
    scrollBehavior( to, from, savedPosition )
    {
        return { x: 0, y: 0 };
    }
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( !from.name && to.path !== '/' && !store.getters[ 'entry/all' ].id )
    {
        // next( '/' );
        // return;
    }

    if( to.fullPath === '/bedankt' )
    {
        let entry = store.getters['entry/all'];

        dataLayer.push({
            event: 'confirmation',
            newsletter_optin: entry.kit,
            transaction_id: 'stl-schietdebal-' + entry.id
        });
    }

    let trackingAlias = {
        '/': '/landing',
        '/game': '/game',
        '/loten': '/loten',
        '/gegevens': '/gegevens',
        '/gegevens/1': '/gegevens-1',
        '/gegevens/2': '/gegevens-2',
        '/gegevens/3': '/gegevens-3',
        '/gegevens/abo': '/gegevens-abo',
        '/overzicht': '/overzicht',
        '/bedankt': '/bedankt'
    };

    dataLayer.push({
        event: 'pageview',
        page_path: trackingAlias[ to.fullPath ]
    });

    // TweenMax.to( 'html, body', 0.5, { scrollTop: 0 });

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
