import Proxy from './Proxy';

class EntryProxy extends Proxy
{
    constructor( parameters = {})
    {
        super( 'api/iban', parameters );
    }
}

export default EntryProxy;
