import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry
    },

    state: {
        fieldState: 'intro',
        showNumberPicker: false
    },

    getters: {
        fieldState: state => state.fieldState,
        showNumberPicker: state => state.showNumberPicker
    },

    mutations: {
        fieldState: ( state, payload ) =>
        {
            state.fieldState = payload;
        },
        showNumberPicker: ( state, payload ) =>
        {
            state.showNumberPicker = payload;
        }
    },

    actions: {
        fieldState: ( context, payload ) =>
        {
            context.commit( 'fieldState', payload );
        },
        showNumberPicker: ( context, payload ) =>
        {
            context.commit( 'showNumberPicker', payload );
        }
    }
});
