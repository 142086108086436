<template>
    <div class="landing">
        <!-- div class="enveloppe" ref="enveloppe">
            <img src="/static/img/enveloppe.png" alt="" />
        </div -->
        <v-panel ref="panel">
            <div slot="ribbon" class="ribbon-wrapper">
                <img src="/static/img/ribbon-campaign.png?v2" />
            </div>

            <transition name="fade">
                <div v-if="codeFirst">
                    <h1>Vul je actiecode in:</h1>
                    <form @submit.prevent="checkCode">
                        <input type="text" v-model="code" placeholder="AA12345"><br />
                        <span class="error" v-if="codeError" style="text-align: center; margin: 0;">Ongeldige code</span>
                        <button class="stl" @click="checkCode">Speel mee!</button>
                    </form>
                </div>
                <div v-else>
                    <h1>Word abonnee en schiet de bal naar je geluksgetal</h1>
                    <!-- p>Maak kans op de één van de vele mooie voetbal prijzen of <strong>&euro;10.000,-</strong>!</p -->
                    <h3 ref="allRewardTitle">Altijd prijs:</h3>
                    <ul class="rewards main" ref="allReward">
                        <li class="free">5 x 1/5 Staatsloten</li>
                    </ul>
                    <h3 ref="rewardsTitle">+ kans op extra prijzen</h3>
                    <ul class="rewards" ref="rewards">
                        <li class="extra"><span>2x</span>&euro;10.000,- netto</li>
                        <li class="extra"><span>1x</span>&euro;5.000,- netto</li>
                        <li><span>50x</span>10 Staatsloten</li>
                        <li><span>150x</span>KNVB Cadeauvoucher t.w.v.&nbsp;&euro;100,-</li>
                        <li><span>500x</span>Juichdoek</li>
                    </ul>
                </div>
            </transition>
            <!-- ul class="info">
                <li>* Bij de eerste vijf trekkingen van dit abonnement, waarbij de Oudejaarstrekking is uitgesloten, ontvang je voor elke succesvolle incasso een gratis extra 1/5 Staatslot ter waarde van €3,00 (of €3,50 als je kiest voor speloptie XL). Indien de incasso niet is geslaagd, komt het gratis 1/5 Staatslot voor deze en de eventueel opvolgende trekkingen te vervallen.</li>
                <li>Je abonnement bestaat uit: 12 trekkingen met een lot van € 15,- of € 17,50 met optie XL . 3 event trekkingen: Koningsdag, 1 juli en 1 oktober trekking met een lot van € 15,-. De oudejaarstrekking met een lot van € 30,-.</li>
                <li><a href="/static/pdf/actievoorwaarden.pdf" target="_blank">Bekijk de actievoorwaarden</a></li>
            </ul -->
            <button class="stl blue" slot="button" @click="goNext">Speel mee!</button>
        </v-panel>
    </div>
</template>

<style lang="scss" scoped>
    .enveloppe {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        img {
            margin: 0 auto;
            transform: translateY(-50%);
        }
    }
</style>

<script>
    import { gsap } from 'gsap';

    import Panel from '@/app/components/Panel';

    export default {
        name: 'Landing',

        components: {
            'v-panel': Panel
        },

        data()
        {
            return {
                codeFirst: window._rootData.codeRequired,
                codeError: false,
                code: ''
            };
        },

        computed: {
        },

        mounted()
        {
            this.$store.dispatch( 'fieldState', 'sky' );
        },

        methods: {

            checkCode()
            {
                this.codeError = false;
                if( this.code.substr( 0, 2 ) === 'TP' && this.code.length === 8 )
                {
                    this.codeFirst = false;
                }
                else
                {
                    this.codeError = true;
                }
            },
            goNext()
            {
                // this.$router.push( '/geluksgetal' );
                this.$router.push( '/gegevens/0' );
            },

            getAnimateIn()
            {
                let tl = gsap.timeline();
                tl.add( () =>
                {
                    this.$store.dispatch( 'fieldState', 'sky' );
                });
                // tl.from( this.$refs.enveloppe, { translateZ: -200, rotateY: '-90deg', autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0.5 );
                // tl.to( this.$refs.enveloppe, { translateZ: -200, rotateY: '90deg', autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 2.35 );
                // tl.from( this.$refs.panel.$el, { translateZ: -200, rotateY: '-90deg', autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0.5 );
                // tl.from( this.$refs.allRewardTitle, { opacity: 0, x: -10, ease: 'sine.out', duration: 0.2 });
                // tl.from( this.$refs.allReward.children, { opacity: 0, x: -10, ease: 'sine.out', duration: 0.2 });
                // tl.from( this.$refs.rewardsTitle, { opacity: 0, x: -10, ease: 'sine.out', duration: 0.2 });
                // tl.from( this.$refs.rewards.children, { opacity: 0, x: -10, ease: 'sine.out', duration: 0.2 }, '+=0.15' );
                return tl;
            },

            getAnimateOut()
            {
                let tl = gsap.timeline();
                tl.to( this.$refs.panel.$el.children, { autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0 );
                tl.to( this.$refs.panel.$el, { translateY: -200, translateZ: -200, rotateY: '90deg', autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0 );
                tl.add( () =>
                {
                    this.$store.dispatch( 'fieldState', 'ball' );
                }, 0 );
                tl.add( () =>
                {
                    this.$store.dispatch( 'fieldState', 'shoot' );
                }, 1.5 );
                tl.add( () =>
                {
                    // nothing
                }, 3.5 );
                return tl;
            }
        }
    };
</script>
