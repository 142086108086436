<template>
    <div :class="{ 'main-layout': true, 'simple': true, [ routeName + '-route']: true }">
        <div :class="{ 'bg-visual': true, 'blur': blurRoutes.indexOf( routeName ) > -1, [fieldState]: true }" ref="bg">
            <div class="grass" ref="grass"></div>
            <div class="ball" ref="ball"><img src="/static/img/ball.png" alt="" ref="ballImg"></div>
            <div class="rewards" ref="rewardElements">
                <img src="/static/img/pilon.png" alt="" class="pilon">
                <img src="/static/img/pilon.png" alt="" class="pilon pilon2">
                <img :src="'/static/img/rewards/' + reward + '.png'" alt="" class="prize">
                <img src="/static/img/rewards/handdoek.png" alt="" class="prize prize2">
            </div>
        </div>

        <img class="stl-label" src="/static/img/stl-label.png" />

        <div class="content">
            <svg class="golden-border" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="'0 0 ' + wWidth + ' ' + wHeight" preserveAspectRatio="none" >
                <defs>
                    <linearGradient id="gold-gradient" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse" gradientTransform="rotate(-15)">
                        <stop offset="0%" stop-color="#e3bd52"/>
                        <stop offset="100%" stop-color="#9d681b"/>
                    </linearGradient>
                    <clipPath id="kijkgat">
                        <rect x="0" y="0" :width="wWidth" :height="borderWidth" />
                        <rect x="0" y="0" :width="borderWidth" height="100%" />
                        <rect :x="( wWidth - borderWidth ) + 'px'" y="0" :width="borderWidth" height="100%" />
                        <rect x="0" :y="(wHeight - borderWidth) + 'px'" :width="wWidth" :height="borderWidth" />
                        <!-- <polygon :points="polyPath" /> -->
                    </clipPath>
                </defs>
                <rect x="0" y="0" width="100%" height="100%" fill="url(#gold-gradient)" clip-path="url(#kijkgat)" />
            </svg>

            <slot></slot>

            <div class="overlayer" v-if="overlay">
                <component :is="overlay.component" v-model="overlay.model"></component>
            </div>
        </div>

        <footer>
            <div class="content-wrapper">
                <!-- <img class="footer-img" src="/static/img/logos/footer.png" /> -->
                <img class="footer-img" src="/static/img/logos/footer-big.png" />
                <p class="disclaimer">Voor het organiseren van Staatsloterij is aan Staatsloterij bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk. <br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a href="/static/pdf/actievoorwaarden.pdf" target="_blank">actievoorwaarden</a>. <br class="hide-for-medium-up" /><a href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse&nbsp;Loterij.</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { gsap } from 'gsap';
    import { EventBus } from '@/app/event-bus';
    export default {
        data()
        {
            return {
                wHeight: document.documentElement.getBoundingClientRect().height,
                wWidth: window.innerWidth,
                borderWidth: 15,

                blurRoutes: [ 'game' ],
                overlay: null
            };
        },

        computed: {
            reward()
            {
                return this.$store.getters['entry/all'].prize || 'none';
            },
            fieldState()
            {
                return this.$store.getters.fieldState;
            },
            routeName()
            {
                return ( this.$route.name ? this.$route.name.toLowerCase() : '' );
            }
        },

        watch: {
            fieldState: function( val )
            {
                gsap.killTweensOf( this.$refs.grass );
                gsap.killTweensOf( this.$refs.ball );
                gsap.killTweensOf( this.$refs.ballImg );
                let tl = gsap.timeline();
                switch( val )
                {
                        case 'intro':
                            tl.set( this.$refs.rewardElements, { autoAlpha: 0 }, 0 );
                            tl.set( this.$refs.grass, { bottom: '0vh', opacity: 1 }, 0 );
                            tl.set( this.$refs.ball, { top: '80vh', left: '50vh' }, 0 );
                            break;
                        case 'sky':
                            tl.set( this.$refs.rewardElements, { autoAlpha: 0 }, 0 );
                            tl.set( this.$refs.grass, { bottom: '-30vh', opacity: 1 }, 0 );
                            tl.set( this.$refs.ball, { top: '90vh', left: '0vh' }, 0 );
                            break;
                        case 'ball':
                            tl.set( this.$refs.grass, { opacity: 1 }, 0 );
                            tl.set( this.$refs.ball, { left: 0, ease: 'sine.out' }, 0 );
                            tl.to( this.$refs.grass, { bottom: '0vh', duration: 1, ease: 'sine.inOut' }, 0 );
                            tl.to( this.$refs.ball, { top: '85vh', duration: 1, ease: 'sine.inOut' }, 0 );
                            break;
                        case 'shoot':
                            tl.to( this.$refs.ball, { scale: 0, duration: 1, ease: 'sine.out' }, 0 );
                            tl.set( this.$refs.ball, { left: 0, ease: 'sine.out' }, 0 );
                            tl.to( this.$refs.ball, { top: '20vh', duration: 0.6, ease: 'circ.out' }, 0 );
                            tl.to( this.$refs.ball, { top: '25vh', duration: 0.6, ease: 'sine.out' }, 0.6 );
                            tl.to( this.$refs.bg, { transformOrigin: '50% 0', scale: 2, duration: 1.5, ease: 'sine.inOut' }, 0.2 );
                            break;
                        case 'clouds':
                            tl.set( this.$refs.grass, { opacity: 0 }, 0 );
                            tl.fromTo( this.$refs.ball, { scale: 1, opacity: 1, top: '50vh', left: '-70vw' }, { scale: 1, opacity: 1, top: '40vh', left: '20vw', duration: 1 }, 0 );
                            tl.to( this.$refs.ballImg, { rotation: '360deg', ease: 'none', duration: 3, repeat: -1 }, 0 );
                            tl.fromTo( this.$refs.bg, { transformOrigin: '50% 0', scale: 1, opacity: 0 }, { transformOrigin: '50% 0', scale: 1, opacity: 1, duration: 0.5 }, 0 );
                            break;
                        case 'top-view':
                            tl.to( this.$refs.ball, { scale: 1, top: '100vh', ease: 'sine.out', duration: 0.01 }, 0 );
                            tl.fromTo( this.$refs.bg, { transformOrigin: '50% 0', scale: 1, opacity: 0 }, { transformOrigin: '50% 0', scale: 1, opacity: 1, duration: 0.5 }, 0 );
                            break;
                        case 'result':
                            tl.set( this.$refs.rewardElements, { autoAlpha: 1 }, 0 );
                            tl.set( this.$refs.ball, { left: 0, scale: 1, top: '100vh' }, 0 );
                            tl.to( this.$refs.ball, { top: '20vh', ease: 'circ.out', duration: 1.5 }, 0.2 );
                            tl.fromTo( this.$refs.bg, { transformOrigin: '50% 0', scale: 1, opacity: 0 }, { filter: 'blur(0px)', transformOrigin: '50% 0', scale: 1, opacity: 1, duration: 0.5 }, 0 );
                            break;
                }
            }
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
            EventBus.$on( 'overlay', this.openOverlay.bind( this ) );
            EventBus.$on( 'overlay:close', this.closeOverlay.bind( this ) );
        },

        methods: {
            onResize()
            {
                this.wHeight = document.documentElement.getBoundingClientRect().height;
                this.wWidth = window.innerWidth;
            },
            openOverlay( data )
            {
                this.overlay = data;
            },
            closeOverlay()
            {
                this.overlay.closed();
                this.overlay = null;
            }
        }
    };
</script>
