import 'babel-polyfill';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import store from '@/app/store';
import VuexRouterSync from 'vuex-router-sync';

import Axios from 'axios';

VuexRouterSync.sync( store, router );

Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

let bgs = [
    '/static/img/huisje-bg.jpg',
    '/static/img/huisje2-bg.jpg',
    '/static/img/huisje3-bg.jpg'
];

for( let bg of bgs )
{
    let img = new Image();
    img.src = bg;
}

if( !window.dataLayer )
{
    window.dataLayer = [];
}

Vue.config.productionTip = false;

store.dispatch( 'entry/merge', window._rootData.player );

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});
